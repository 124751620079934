
// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon, IonText,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
        IonList, IonListHeader, IonItem, IonLabel, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';
import PaymentInfo from '@/components/PaymentInfo.vue';

// icons
import { logIn, checkmarkCircle, chevronForward, alertCircle, } from 'ionicons/icons';

// services
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default {
  name: 'ThankYouPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon, IonText,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
                IonList, IonListHeader, IonItem, IonLabel,
                LogoImg, PaymentInfo, },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { orderId, orderCode, orderStatus, paymentMethod } = route.params;

    return {
      // icons
      logIn, checkmarkCircle, chevronForward, alertCircle,

      // variables
      orderId, orderCode, orderStatus, paymentMethod,

      // methods
      t,
    }
  },
}
